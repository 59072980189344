
/*NAVBAR*/
.modal-open{
  nav{
    &.navbar{
      top: -70px;
    }
  }
}

.fp-viewing-section-krundi{
  nav .navbar-header .navbar-brand.brand-img img.krundi-logo{
    opacity: 0;
    top: -300px;
  }
}
.fp-viewing-section-historia--krund,
.fp-viewing-section-historia--krundi,
.fp-viewing-section-perfil,
.fp-viewing-section-instagram{
  nav.navbar-shrink .navbar-header .navbar-brand.brand-img img.krundi-logo{
    opacity: 1;
    top: 50%;
  }

  nav{
    &.navbar{
      background-color: black;
      .navbar-header{
        height: 55px;
        .navbar-brand{
          &.brand-img{
            img{
              margin-top: 20px;
            }
          }
        }
      }
      .border-texture {
        &.first{
          bottom: -25px;
          left: 0;
          right: 0;
          z-index: -1;
          opacity: 1;
          @include transform(rotate(180deg));
        }
      }
    }
  }

}

.fp-viewing-section-krundi{
  nav .navbar-header .navbar-brand.brand-img img.cerro-logo{
    width: 120px;
    opacity: 1;
  }
}
.fp-viewing-section-historia--krund,
.fp-viewing-section-historia--krundi,
.fp-viewing-section-perfil,
.fp-viewing-section-instagram{
  nav.navbar-shrink .navbar-header .navbar-brand.brand-img img.cerro-logo{
    width: 120px;
    opacity: 0;
    top: -300px;
  }
}

nav{
	&.navbar{
    position: fixed;
    width: 100%;
    top: 0;
    padding: 10px 0;
    z-index: 222;
		border-color: transparent;
    background-color: transparent;
    @include transition;
    &.navbar-shrink{
	    background-color: black;
      .navbar-header{
        height: 55px;
        .navbar-brand{
          &.brand-img{
            img{
              margin-top: 20px;
            }
          }
        }
      }
      .border-texture {
        &.first{
          bottom: -25px;
          left: 0;
          right: 0;
          z-index: -1;
          opacity: 1;
          @include transform(rotate(180deg));
        }
      }
		}
	}
	.navbar-header{
    @include transition;
    height: 80px;
    position: relative;
    width: 100%;
		.navbar-brand{
			cursor: pointer;
	    padding: 4px 0px 8px;
      display: inline;
      font-size: 0;
	    &.brand-img{
	    	img{
			    display: block;
			    position: absolute;
			    top: 50%;
			    left: 50%;
          @include transition;
		      @include transform(translate(-50%, -50%));
	    	}
        .cerro-logo{
          width: 120px;
          opacity: 0;
          top: 60%;
          left: 40px;
        }
	    }
		}
		.navbar-toggle {
      width: 25px;
      height: 33px;
	    position: absolute;
      top: 50%;
      left: 0;
      margin: 0;
      margin-top: -16px;
      display: block;
      cursor: pointer;
      border-radius: 0;
      background-color: transparent;
      background-image: none;
      border: 1px solid transparent;
      span{
        position: absolute;
        top: 10px;
        display: block;
        border-radius: 1px;
        background-color: #fff;
        height: 1px;
        width: 23px;
      }
      .icon-bar:nth-child(2) {
        top: 15px;
      }
      .icon-bar:nth-child(3) {
        top: 20px;
      }
		}
	}
	#navbar{
		.navbar-nav {
			margin: 0;
		    >li{
		    	margin-left: 0px;
	    	    border-right: 1px solid #9e9e9e;
		    	&:nth-child(1){
		    		margin-left: 0px;
		    	    border-left: 1px solid #9e9e9e;
		    	}
				a{
				    font-size: 12px;
					&:hover,
					&:focus{
					    background-color: transparent !important;
					}
				}
		    }
		    li.active {
			    margin-bottom: 2px;
			    border-bottom: 3px solid #6bb5c1;
			}
			li.active > a{

        border-bottom: 3px solid #6bb5c1;
		    margin-bottom: -3px;
			}
		}
	}			
}

#wrapper{
	position: relative;
  padding-left: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
	#sidebar-wrapper {
    width: 0;
    position: fixed;
    left: 200px;
    top: 0;
    bottom: 0px;
    z-index: 222;
    padding-top: 50px;
    margin-left: -200px;
    overflow: hidden;
    background: #191919;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    .sidebar-nav{
    	margin-top: 21px;
	    padding: 0;
	    li {
        text-indent: 20px;
        &.dropper{
          i{
            width: 50px;
            @include transition;
          }
        }
  			&.drop{
  			  display: none;
  			  text-indent: 20px;
          float: left;
          width: 50%;
  			}
		    a {
			    display: block;
	        font-size: 16px;
			    color: #eee;
			    font-family: $primary-Bk;
	        letter-spacing: 1.8px;
			    padding: 12px 0px 10px 15px;
			    -webkit-text-decoration: none;
			    text-decoration: none;
			    white-space: nowrap;
			    text-overflow: ellipsis;
			    overflow: hidden;
			    &:hover {
			    	-webkit-text-decoration: none;
				    text-decoration: none;
				    background: rgba(0, 0, 0, 1);
					}
				}
			}
  	}
		.social-block{
	    position: absolute;
	    margin-top: 0;
	    bottom: 10px;
      max-height: 29px;
	    overflow: hidden;
	    // display: none;
		}
	}
	.sidebar-closebox {
    position: fixed;
    top: 0;
    bottom: 0px;
    right: 0px;
    left: 0px;
    opacity: 0;
    background-color: transparent;
    background-color: rgba(0, 0, 0, .8);
    display: none;
    z-index: 170;
	}
	&.sidebar-toggle{
		#sidebar-closebox {
	    display: block;
	    opacity: 1;
		}
		#sidebar-wrapper {
	    width: 270px;
	    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		}
		#page-content-wrapper{
			.navbar-toggle{
				.icon-bar{
			    height: 2px;
					&:nth-child(1){
            top: 15px;
				    -webkit-transform: rotate(-45deg) translate(0px, 0px);
				    transform: rotate(-45deg) translate(0px, 0px);
					}
					&:nth-child(2){
		        opacity: 0;
		        display: none;
					}
					&:nth-child(3){
            top: 15px;
				    -webkit-transform: rotate(45deg) translate(-15px, -15px);
				    transform: rotate(45deg) translate(0px, 0px);
					}
				}
			}
		}
	}
}

// RESPONSIVE

/*NAVBAR*/
@media (min-width: 768px) {
	#wrapper{
		&.sidebar-toggle{
			#sidebar-wrapper {
		    width: 350px;
      }
    }
    #sidebar-wrapper {
      .sidebar-nav{
        li {
          &.drop{
            text-indent: 40px;
            width: 100%;
          }
        }
      }
    }
  }
  nav{
    .navbar-header{
      height: 130px;
      .navbar-brand{
        &.brand-img{
          img{
            left: 55px;
            @include transform(translate(0%, -50%));
          }
          .cerro-logo{
            width: initial;
            opacity: 1;
            top: 50%;
            left: 55px;
          }
        }
      }
    }

    &.navbar{
      &.navbar-shrink{
        .navbar-header{
          .navbar-brand{
            &.brand-img{
              img{
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }
  .fp-viewing-section-krundi{
    nav .navbar-header .navbar-brand.brand-img img.cerro-logo{
      width: initial;
    }
  }
  .fp-viewing-section-historia--krund,
  .fp-viewing-section-historia--krundi,
  .fp-viewing-section-perfil,
  .fp-viewing-section-instagram{
    nav.navbar-shrink .navbar-header .navbar-brand.brand-img img.cerro-logo{
      width: initial;
      opacity: 1;
      top: 50%;
    }
  }
  
}




@media (min-width: 992px) {}
@media (min-width: 1200px) {}

