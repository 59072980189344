// -------------------------
// HELPERS
// -------------------------

a{color: $tertiary; text-decoration: none;   &:hover{ color: #333;}}
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus{outline: none;outline: none;outline-offset: 0px;}
.btn:active, .btn.active {-webkit-box-shadow: none;box-shadow: none;}
a, li, button{outline:0;&:hover,&:focus,&:visited{outline:0; text-decoration: none;}}
a:-webkit-any-link {text-decoration: none;}
ul, ol{list-style: none; margin: 0;}
img{display: block; max-width: 100%; height: auto;} 
img {user-drag: none; user-select: none; -moz-user-select: none; -webkit-user-drag: none; -webkit-user-select: none; -ms-user-select: none;}

.relative {position: relative;}
.none {display: none !important;} 
.block {display: block !important;}
.uppercase {text-transform: uppercase;}
.inline {display: inline-block;}
.img-center {margin: 0 auto;}
.no-limit-fluid {max-width: none;}
.full-width {width: 100%;}
.width-50 {width: 50%; display: inline-block;}
.col-sides--0{padding: 0;}
.justify{text-align: justify;}

.rotate45{@include transform(rotate(45deg));};
.rotate90{@include transform(rotate(90deg));};
.rotate180{@include transform(rotate(180deg));};
.rotate369{@include transform(rotate(369deg));};
.white-space{white-space: nowrap;}
.ellipsis {width: 100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.center-top-left{ position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%));}
.center-left{ position: absolute; top: 0; left: 50%; @include transform(translate(-50%, 0));}
.center-top{ position: absolute; top: 50%; -webkit-transform: translate(0,-50%); transform: translate(0,-50%);}

