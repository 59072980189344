// -------------------------
// FONTS
// -------------------------

.font12{
	font-size: 12px !important;
}
.font13{
	font-size: 13px !important;
}
.font15{
	font-size: 15px !important;
}
.font17{
	font-size: 17px !important;
}
.font18{
	font-size: 18px !important;
}
.font19{
	font-size: 19px !important;
}
.font20{
	font-size: 20px !important;
}
.font21{
	font-size: 21px !important;
}
.font22{
	font-size: 22px !important;
}
.font23{
	font-size: 23px !important;
}
.font24{
	font-size: 24px !important;
}
.font25{
	font-size: 25px !important;
}
.font26{
	font-size: 26px !important;
}
.font27{
	font-size: 27px !important;
}
.font28{
	font-size: 28px !important;
}
.font29{
	font-size: 29px !important;
}
.font30{
	font-size: 30px !important;
}

.font78 {font-size:78px !important;line-height: 1.1;}
.font72 {font-size:72px !important;line-height: 1.1;}
.font52 {font-size:52px !important;line-height: 1.1;}
.font48 {font-size:48px !important;line-height: 1.1;}
.font42 {font-size:42px !important;line-height: 1.1;}
.font36 {font-size:36px !important;line-height: 1.1;}
.font32 {font-size:32px !important;line-height: 1.1;}
.font28 {font-size:28px !important;line-height: 1.1;}
.font26 {font-size:26px !important;line-height: 1.1;}
.font24 {font-size:24px !important;line-height: 1.1;}
.font20 {font-size:20px !important;line-height: 1.3;}
.font18 {font-size:18px !important;}
.font16 {font-size:16px !important;}
.font15 {font-size:15px !important;}
.font14 {font-size:14px !important;}
.font13 {font-size:13px !important;}
.font12 {font-size:12px !important;}
.font11 {font-size:11px !important;}
.font10 {font-size:10px !important;}
.font9  {font-size:9px !important;}



