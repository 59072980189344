// -------------------------
// FULL PAGE
// -------------------------

html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow:hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
#superContainer {
  height: 100%;
  position: relative;
  /* Touch detection for Windows 8 */
  -ms-touch-action: none;
  /* IE 11 on Windows Phone 8.1*/
  touch-action: none;
}
.fp-section {
  position: relative;
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}
.fp-slide {
  float: left;
}
.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block;
}
.fp-slides {
  z-index:1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out; /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out;
}
.fp-section.fp-table, .fp-slide.fp-table {
  display: table;
  table-layout:fixed;
  width: 100%;
}
.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.fp-slidesContainer {
  float: left;
  position: relative;
}
.fp-controlArrow {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  z-index: 4;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  -webkit-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.fp-controlArrow.fp-prev {
  // left: 15px;
  // width: 0;
  // border-width: 38.5px 34px 38.5px 0;
  // border-color: transparent #fff transparent transparent;
}
.fp-controlArrow.fp-next {
  // right: 15px;
  // border-width: 38.5px 0 38.5px 34px;
  // border-color: transparent transparent transparent #fff;
}
.fp-scrollable {
  overflow: hidden;
  position: relative;
}
.fp-scroller{
  overflow: hidden;
}
.iScrollIndicator{
  border: 0 !important;
}
.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}
#fp-nav {
  position: fixed;
  z-index: 9;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  @include transform(translate(0%, -50%));
  display: none;
}

@media (min-width: 768px) {
  #fp-nav {
    display: block;
  }
}

#fp-nav.right {
  right: 17px;
}
#fp-nav.left {
  left: 17px;
}
.fp-slidesNav{
  position: absolute;
  z-index: 4;
  left: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.fp-slidesNav.bottom {
  bottom: 17px;
}
.fp-slidesNav.top {
  top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
  // display: inline-block;
  width: 24px;
  height: 23px;
  margin: 10px;
  position:relative;
}
.fp-slidesNav ul li {
  display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span{
  height: 26px;
  width: 26px;
  margin: -13px 0 0 -13px;
  border-radius: 100%;
  background: #333;
 }
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 16px;
  width: 16px;
  background: transparent;
  border: solid 1px #333;
  left: 50%;
  top: 50%;
  margin: -8px 0 0 -8px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
  // width: 10px;
  // height: 10px;
  // margin: -5px 0px 0px -5px;
  background-color: #333;
}
#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}
#fp-nav ul li .fp-tooltip.right {
  right: 20px;
}
#fp-nav ul li .fp-tooltip.left {
  left: 20px;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell{
  height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important;
}