// -------------------------
// FOOTER
// -------------------------

// Footer CONSTRUCTION
footer{
  min-height: 0px;
  background-color: #000;
  padding: 10px 30px 10px;
  .crj-terms,
  .developed-thet{
    width: 100%;
  }
  .crj-terms{
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    p{
      color: #949494;
      font-size: 14px;
    }
  }
  .developed-thet{
    text-align: center;
    p{
      display: inline-block;
      margin-right: 4px;
      font-size: 14px;
      // text-transform: uppercase;
    }
    img{
      width: 35px;
      display: inline-block;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      margin-top: -2px;
    }
    a{
      color: #949494;
      &:hover, &:focus, &:visited{
        color: #fff;
      }
      p{
        color: #949494;
      }
      &:hover{
        p{
          color: white;
        }
        img{
          -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
          filter: grayscale(0%);
        }
      }
    }
  }
}



// RESPONSIVE
@media (min-width: 768px){
  footer{
    .crj-terms,
    .developed-thet{
      width: 50%;
    }
    &.padding-footer{
      padding: 20px 20px 44px;
    }
    .crj-terms{
      margin-bottom: 0px;
      p{
        float: left;
      }
      a{
        font-size: 9px;
      }
    }
    .developed-thet{
      text-align: right;
    }
  }
}

